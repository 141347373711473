<template>
  <div class="second-text">
    {{ dateFormatter(new Date(value * 1000)) }}
  </div>
</template>

<script>
/**
 * component for displaying date in datatable columns
 * api name = date
 */
export default {
  name: "DateColumn",
  props: ["value"],
  methods: {
    dateFormatter(date) {
      let d = date;
      return [
        d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
        d.getFullYear(),
      ].join("/");
    },
  },
};
</script>
