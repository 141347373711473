<template>
  <span class="second-text my-1">
    <div class="d-flex align-content-left w-auto px-2">
      <span class="symbol symbol-35 symbol-light-success text-left px-1">
        <img
          style="border-radius: 50% !important"
          v-if="value.photo.url"
          alt=""
          :src="value.photo.url"
        />
        <span
          v-else
          class="symbol-label font-size-h5 font-weight-bold"
          :style="`background-color:${value.photo.color};`"
          style="border-radius: 50% !important"
        >
          {{ value.photo.first_letter.toUpperCase() }}
        </span>
      </span>

      <span class="text-dark-50 font-size-base w-auto p-1">
        {{ value.name }}
      </span>
    </div>
  </span>
</template>

<script>
/**
 * component for showing user name and photo in datatable column
 * api name = userPhoto
 * */
export default {
  name: "UserPhoto",
  props: ["value"],
};
</script>
