<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center py-3">
        <h3 class="mb-0 font-size-h5 font-weight-bolder">{{ headerText }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-250px scroll-y mx-5">
        <div class="h-75px">
          <v-autocomplete
            v-model="formData.sku_id"
            :items="skus"
            item-text="text"
            item-value="index"
            label="SKUS"
            dense
            outlined
            clearable
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            :error-messages="skuIdErrors"
            @blur="$v.formData.sku_id.$touch()"
          />
        </div>
        <div class="h-75px">
          <v-text-field
            v-model="formData.quantity"
            label="Quantity"
            type="number"
            @change="() => validateMinValue('quantity', 0, 'formData')"
            dense
            outlined
            clearable
            hide-details
            :error-messages="quantityErrors"
            @blur="$v.formData.quantity.$touch()"
          />
        </div>

        <!--begin::Actions-->
        <div class="mb-2 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import {
  EDIT_RULES_SKUS,
  SET_RULES_SKUS,
} from "@/core/services/store/subscriptionManagement.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "AddEditSKUSModal",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      sku_id: { required },
      quantity: { required },
    },
  },
  data: () => ({
    dialog: false,
    lastElementIndex: null,
    entries: [],
    search: null,
    isLoading: false,
    formData: {
      sku_id: null,
      quantity: null,
      systemCode: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      this.formData.systemCode = this.skus[0].text;
      if (this.lastElementIndex !== null) {
        let data = {
          newItem: this.formData,
          lastElementIndex: this.lastElementIndex,
        };
        this.$store.commit(EDIT_RULES_SKUS, data);
      } else this.$store.commit(SET_RULES_SKUS, this.formData);

      this.toggleModal();
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        sku_id: null,
        quantity: null,
        systemCode: null,
      };
      this.entries = [];
      this.search = null;
      this.isLoading = false;
    },
    toggleModal() {
      if (this.dialog) this.resetFormData();
      this.dialog = !this.dialog;
    },
    initData(index, newItem) {
      this.lastElementIndex = index;
      this.getData(newItem.systemCode);
      this.formData = newItem;
      this.dialog = true;
    },
    async getData(value) {
      const requestBody = { q: value };
      if (this.$store.getters.getSUBSCRIPTIONSSelectedClient)
        requestBody.client = this.$store.getters.getSUBSCRIPTIONSSelectedClient;

      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.skus) {
            this.entries = res.skus.map((item) => ({
              index: item.skuId,
              text: item.systemCode,
            }));
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    headerText: function () {
      if (this.lastElementIndex !== null) return "Edit SKU";
      else return "Add new SKU";
    },
    submitButtonText: function () {
      if (this.lastElementIndex !== null) return "Update";
      else return "Submit";
    },
    skus: function () {
      return this.entries;
    },
    skuIdErrors: function () {
      return this.handleFormValidation("sku_id");
    },
    quantityErrors: function () {
      return this.handleFormValidation("quantity");
    },
  },
  watch: {
    search(val) {
      this.isLoading = true;
      this.getData(val);
    },
  },
};
</script>
