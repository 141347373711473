<template>
  <VueTelInputVuetify
    class="ma-0 pa-0 mx-1"
    outlined
    v-model="filterData"
    :inputOptions="{ showDialCode: false, tabindex: 0 }"
    mode="international"
    dense
    placeholder=""
    disabledFetchingCountry
    defaultCountry="AE"
  ></VueTelInputVuetify>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
export default {
  name: "FilterPhone",
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
  },
  components: {
    VueTelInputVuetify,
  },
  data: () => ({
    filterData: null,
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}`]) {
      this.filterData = this.filterAllData[`${this.filter.name}`];
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
  },
  methods: {
    resetFilterData() {
      this.filterData = null;
    },
  },
  watch: {
    filterData: {
      handler() {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData;
        this.$store.commit(SET_FILTERS_DATA, all);
      },
      deep: true,
    },
  },
};
</script>
