<template>
  <v-menu
    outlined
    v-model="menu2"
    class=""
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    dense
  >
    <v-date-picker
      v-model="filterData"
      range
      clearable
      :max="new Date().toISOString().split('T')[0]"
    ></v-date-picker>

    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        v-model="filterData"
        :label="$t(filter.title)"
        append-icon="mdi-calendar"
        clearable
        dense
        class="mx-1"
        elevation-0
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
  </v-menu>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
export default {
  name: "FilterDate",
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    filterData: null,
    menu2: false,
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}`]) {
      this.filterData = this.filterAllData[`${this.filter.name}`];
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
  },
  methods: {
    resetFilterData() {
      this.filterData = null;
    },
  },
  watch: {
    filterData: {
      handler() {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData;
        this.$store.commit(SET_FILTERS_DATA, all);
      },
      deep: true,
    },
  },
};
</script>
