<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <div class="bg-white poppins">
      <div class="modal-body d-flex flex-column h-400px scroll-y mx-5 py-7">
        <SKUSDataTable :forView="forView" />

        <div class="mt-6 d-flex flex-grow-1 align-end justify-center">
          <button
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="dialog = false"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import SKUSDataTable from "@/own/components/orderManagement/subscriptionManagement/SKUSDataTable";
export default {
  name: "ViewSKUSModal",
  components: { SKUSDataTable },
  props: {
    forView: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
