<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column min-h-400px scroll-y mx-5 py-7">
        <!--begin::Actions-->
        <v-data-table
          v-if="items"
          :headers="headers"
          :items="items"
          :items-per-page="10"
          hide-default-footer
          fixed-header
          light
          dense
        >
          <!--    no-data-->
          <template v-slot:no-data> NO DATA HERE!</template>
          <template v-slot:no-results> NO RESULTS HERE!</template>
          <!--    no-data end-->
          <template v-slot:top>
            <div class="d-flex justify-content-between">
              <datatable-detail
                class="mt-4 mb-8"
                :table_properties="table_properties"
                v-if="$vuetify.breakpoint.smAndUp"
              ></datatable-detail>
              <div class="mt-6 mr-4">
                <button
                  @click.prevent="showActionDialog('create')"
                  class="btn btn--export-filter p-3"
                >
                  <span class="svg-icon">
                    <v-icon size="18">mdi-plus-box-outline</v-icon>
                  </span>
                  <span>Add Header</span>
                </button>
              </div>
            </div>
          </template>
          <template v-slot:item.action="item">
            <v-menu left transition="scale-transition" offset-y bottom rounded>
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-bind="attrs"
                  v-on="on"
                  class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
                >
                  Actions
                  <span class="svg-icon svg-icon-5 m-0">
                    <v-icon small>mdi-chevron-down</v-icon>
                  </span>
                </a>
              </template>
              <v-card class="poppins py-1" elevation="0">
                <v-btn
                  @click.prevent="showActionDialog('edit', item)"
                  class="d-block w-100"
                  text
                  >Edit</v-btn
                >
                <v-btn
                  @click.prevent="deleteHeader(item)"
                  class="d-block w-100 text-danger"
                  text
                  >Delete</v-btn
                >

                <!-- {{ item.extra_data.actions }} -->
              </v-card>
            </v-menu>
          </template>
        </v-data-table>
        <!-- action dialog -->
        <HeadersActionDialog
          ref="headerActionDialog"
          :refresher="loadCustomHeaderData"
          :pageLoader="pageLoader"
          :action_type="action_type"
          :item="item"
        />
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
      <div class="pb-7 d-flex flex-grow-1 align-end justify-center">
        <button class="btn btn-info px-5 py-3 ls1" @click="toggleModal">
          Close
        </button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service.js";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import HeadersActionDialog from "@/own/components/datahub/Webhooks/HeadersActionDialog.vue";
import Swal from "sweetalert2";
// import ManageHeadersTable from "@/own/components/datahub/Webhooks/ManageHeadersTable.vue";

export default {
  name: "ManageHeaders",
  components: { DatatableDetail, HeadersActionDialog },
  data: () => ({
    dialog: false,
    headers: null,
    items: null,
    table_properties: null,
    action_type: "create",
    item: null,
  }),
  methods: {
    toggleModal() {
      if (!this.dialog) {
        this.loadCustomHeaderData();
      }
      this.dialog = !this.dialog;
    },
    async loadCustomHeaderData() {
      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        webhook_id: this.itemForAction.id,
      };

      const structure = await ApiService.post(
        `/datahub/webhooks_headers`,
        payload
      );
      const datatable = await ApiService.post(
        `/datahub/webhooks_headers/datatable`,
        payload
      );

      this.headers = structure.data.data.columns;
      this.table_properties = structure.data.data.properties;
      this.items = datatable.data.data.data;

      this.$store.commit(SET_PAGE_LOADING, false);
      // ApiService.post(`/datahub/webhooks_headers`, payload)
      //   .then(({ data }) => {
      //     this.headers = data.data.columns;
      //   })
      //   .then(() => {
      //     ApiService.post(`/datahub/webhooks_headers/datatable`, payload).then(
      //       ({ data }) => {
      //         this.items = data.data.data;
      //       }
      //     );
      //   })
      //   .finally(() => {
      //     this.$store.commit(SET_PAGE_LOADING, false);
      //   });
    },
    showActionDialog(type = "create", item) {
      this.action_type = type;
      if (type === "edit") {
        this.item = item.item;
      }
      setTimeout(() => {
        this.$refs.headerActionDialog.toggleModal();
      }, 100);
    },
    deleteHeader(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoader(true);
          //TODO: change data sending to id
          const payload = {
            webhook_id: this.itemForAction.id,
            id: item.item.id,
          };
          ApiService.post(`/datahub/webhooks_headers/destroy`, payload)
            .then(() => {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
              this.loadCustomHeaderData();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        }
      });
    },
    pageLoader(val = true) {
      this.$store.commit(SET_PAGE_LOADING, val);
    },
  },
  computed: {
    itemForAction() {
      return this.$store.getters.getItemForAction;
    },
  },
};
</script>
