<template>
  <div v-if="value">
    <a
      href="#"
      class="btn btn-light-linkedin btn-sm px-4 ls1 text-no-wrap"
      @click="toggleModal"
      >{{ value.title }}</a
    >
    <div>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        persistent
        max-width="1000px"
      >
        <div class="bg-white poppins">
          <div class="modal-header align-center">
            <h3 class="mb-0 font-weight-bolder">{{ value.title }}</h3>
            <div
              class="btn btn-sm btn-icon btn-active-light-info"
              @click="dialog = false"
            >
              <span class="svg-icon">
                <v-icon size="22">mdi-close</v-icon>
              </span>
            </div>
          </div>
          <div
            class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7"
          >
            <!--begin::Body-->
            <div class="text-center pa-1" style="height: 100vh; width: 100%">
              <!--              <div class="d-block templateTable" v-if="value.data" v-html="value.data"></div>-->
              <iframe
                @load="fillIframe"
                ref="iframe"
                style="height: 100% !important; width: 100% !important"
                v-if="value.data"
                frameborder="0"
                width="100%"
                height="100%"
              >
              </iframe>
              <div v-else class="text-center">{{ $t("no_data") }}</div>
              <!-- content -->
            </div>
            <!--end::Body-->
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
/**
 * show html content on pop up
 */
export default {
  name: "ShowHtmlPopUp",
  props: ["value"],
  data: () => ({
    dialog: false,
  }),

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    htmlPageMaker(data) {
      let page = `<!DOCTYPE html>
                  <html>
                    <head>
                    <style>
                        body > table {
                        width: 100%!important;
                        height: 100% !important;
                        }
                    </style>
                    </head>
                    <body style="height: 100%; width: 100%;">
                      ${data}
                    </body>
                  </html>`;
      return page;
    },
    fillIframe() {
      if (this.value.data) {
        const iFrameEl = this.$refs.iframe;
        const doc = iFrameEl.contentWindow;
        const page = this.htmlPageMaker(this.value.data);
        doc.document.open();
        doc.document.write(page);
        doc.document.close();
      }
    },
  },
};
</script>

<style>
.templateTable > table {
  width: 100% !important;
}
</style>
