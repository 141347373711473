<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
    v-if="serverData"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add Country</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <form id="createForm" class="py-3 row">
          <div class="h-75px my-0 col-12">
            <v-autocomplete
              dense
              multiple
              outlined
              v-model="formData.countries"
              :items="serverData.countries"
              item-name="text"
              item-value="index"
              label="Countries"
              clearable
              :error-messages="countriesErrors"
              @input="$v.formData.countries.$touch()"
              @blur="$v.formData.countries.$touch()"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="selectAll">
                  <v-list-item-action>
                    <v-checkbox v-model="checkbox" disabled></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>
            </v-autocomplete>
          </div>

          <!--end::Body-->
        </form>

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center mt-10">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { addItemMessage } from "@/core/constants/globalVariables";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      countries: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      countries: null,
    },
    checkbox: false,
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/validations/datahub/integration_countries/create", {
        integration_id: this.$store.getters.getINTEGRATIONS2IntegrationId,
      })
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
        this.serverData = null;
        this.checkbox = !this.checkbox;
      } else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },

    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.formData;
        data.integration_id = this.$store.getters.getINTEGRATIONS2IntegrationId;
        ApiService.post(
          "/validations/datahub/integration_countries/store",
          data
        )
          .then(() => {
            Swal.fire({
              title: "Created",
              text: `${addItemMessage}`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        countries: null,
        shipping_type_id: null,
        store_code: null,
      };
    },
    selectAll() {
      this.checkbox = !this.checkbox;
      if (this.checkbox) {
        this.formData.countries = this.serverData.countries.map(
          (item) => item.index
        );
      } else {
        this.formData.countries = [];
      }
    },
  },
  computed: {
    countriesErrors() {
      return this.handleFormValidation("countries");
    },
  },
};
</script>
