<template>
  <div v-if="table_permissions.length > 0" class="custom-content-height">
    <!-- slot top -->
    <div v-if="$slots.top">
      <slot name="top"></slot>
    </div>
    <!-- slot top end -->
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      :height="getvh(55)"
      :show-select="!disableSelectAllCheckbox"
      :single-select="disableSelectAllCheckbox"
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      :item-key="id_field_name"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      mobile-breakpoint="40"
      @dblclick:row="showRecordDetail"
      @click:row="rowClicked"
    >
      <!--    no-data-->
      <template v-slot:no-data> {{ $t("no_data_here") }}</template>
      <template v-slot:no-results> {{ $t("no_results_here") }}</template>
      <!--    no-data end-->
      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-1"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>
          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <slot
              name="topActions"
              :permissions="permissionChecker"
              :pageLoader="pageLoad"
              :selectedItems="selected"
              :updateTable="updateTableContent"
            ></slot>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="bulkRemoveOption && selected.length > 0"
            >
              <button
                @click.prevent="resetSelectedItems"
                class="btn btn--export-filter font-weight-bolder"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-refresh</v-icon>
                </span>
                {{ $t("reset") }}
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('create') && !skipAddButton"
            >
              <button
                @click.prevent="showAddItemModal"
                class="btn btn--export-filter font-weight-bolder"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-plus-box-outline</v-icon>
                </span>
                <template v-if="isAddBtnCustom">
                  <span v-if="addButtonText">{{ addButtonText }}</span>
                </template>
                <template v-else>
                  <span v-if="addButtonText"
                    >{{ $t("add") }} {{ addButtonText }}</span
                  >
                  <span v-else>
                    {{ $t("add") }} {{ $t(table_properties.title) }}
                  </span>
                </template>
              </button>
            </div>

            <!--begin::Button-->

            <v-menu
              offset-y
              v-if="permissionChecker('export') && export_types.length > 0"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
                  :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
                >
                  <button
                    v-bind="attrs"
                    v-on="on"
                    class="btn btn--export-filter font-weight-bolder"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-export-variant</v-icon>
                    </span>
                    {{ $t("export") }}
                  </button>
                </div>
              </template>
              <v-list>
                <v-list-item v-for="(data, index) in export_types" :key="index">
                  <v-list-item-title>
                    <v-btn block @click="actionMultiExport(data)"
                      >{{ data.label }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-else-if="permissionChecker('export') && exportHeaders"
            >
              <button
                @click="handleExportSidebarClick"
                class="btn btn--export-filter font-weight-bolder"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                {{ $t("export") }}
              </button>
            </div>

            <!--end::Button-->

            <div
              class="mr-sm-1 mb-1 mr-0 pa-0"
              :class="{
                'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly,
                'mr-md-2': !hasTopRightActions,
              }"
              v-if="filters.length > 0"
            >
              <button
                @click="handleFilterSidebarClick"
                class="btn btn--export-filter font-weight-bolder"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                {{ $t("filter") }}
              </button>
            </div>

            <slot
              name="topRightActions"
              :permissions="permissionChecker"
              :pageLoader="pageLoad"
              :selectedItems="selected"
              :updateTable="updateTableContent"
            ></slot>

            <component
              v-bind:is="multiUploadComponent"
              v-if="
                (permissionChecker('upload') &&
                  table_properties.title === 'SKUs') ||
                table_properties.title === 'Products'
              "
              :types="$store.getters[store_names.getUploadType]"
              :actionFunction="showUploadModal"
              :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
            ></component>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-else-if="permissionChecker('upload')"
            >
              <button
                class="btn btn--export-filter font-weight-bolder"
                @click.prevent="
                  () =>
                    showUploadModal($store.getters[store_names.getUploadType])
                "
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-cloud-upload</v-icon>
                </span>
                {{ $t("import") }}
              </button>
              <component
                v-if="permissionChecker('upload')"
                v-bind:is="uploadComponent"
                ref="upload"
                :updateTableContent="updateTableContent"
              ></component>
            </div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('print')"
            >
              <button
                class="btn btn--export-filter font-weight-bolder"
                @click.prevent="showGenerateModal"
              >
                {{ $t("generate") }}
              </button>
              <component
                v-if="permissionChecker('print')"
                v-bind:is="generateComponent"
                ref="generate"
                :updateTableContent="updateTableContent"
              ></component>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('reprocess')"
            >
              <button
                class="btn btn--export-filter font-weight-bolder"
                @click.prevent="applyReprocessAction"
              >
                {{ $t("reprocess") }}
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('redirect_uom_conversion')"
            >
              <button
                class="btn btn--export-filter font-weight-bolder"
                @click.prevent="
                  $router.push('/warehouse_management/uom_conversions')
                "
              >
                {{ $t("uom_conversion") }}
              </button>
            </div>
            <component
              v-bind:is="printComponent"
              v-if="permissionChecker('sku_barcode_generate')"
              :items="selected"
              :types="[
                { name: 'BARCODE', value: 'barcode2d' },
                { name: 'QR', value: 'barcodeQr' },
              ]"
              :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
            ></component>
          </div>
        </div>
        <!-- Export Sidebar start -->

        <ExportSidebar
          v-if="permissionChecker('export') && export_types.length > 0"
          ref="exportSidebar"
          :exportHeaders="multiExportColumns"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <ExportSidebar
          v-else-if="permissionChecker('export') && exportHeaders"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->
      </template>
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <!-- column -->
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
          class="zIndex-0"
          style="z-index: -1 !important"
        ></ComponentSelector>
      </template>
      <template #item.action="{ item, header, value }">
        <component
          v-if="actionsComponent != null"
          v-bind:is="actionsComponent"
          :item="item"
          :actions="actions"
          :handle_function_call="handle_function_call"
        >
          <slot
            name="rowActionsDropdown"
            :permissions="permissionChecker"
            :pageLoader="pageLoad"
            :item="item"
            :updateTable="updateTableContent"
          ></slot>
        </component>
        <slot
          v-else
          name="rowActions"
          :permissions="permissionChecker"
          :pageLoader="pageLoad"
          :item="item"
          :updateTable="updateTableContent"
        ></slot>
      </template>

      <!--        <DataTableActionSelector-->
      <!--          :item="item"-->
      <!--          :actions="actions"-->
      <!--          :handle_function_call="handle_function_call"-->
      <!--        ></DataTableActionSelector>-->
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <component
      v-if="permissionChecker('create')"
      v-bind:is="addComponent"
      :pageLoader="pageLoad"
      :refresher="updateTableContent"
      ref="addItem"
    ></component>
    <component
      v-if="this.permissionChecker('update')"
      v-bind:is="editComponent"
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="editModal"
    ></component>
    <component
      v-if="this.permissionChecker('duplicate')"
      v-bind:is="duplicateComponent"
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="duplicateModal"
    ></component>
    <component
      v-bind:is="detailsComponent"
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="detailsModal"
    ></component>
    <component
      v-if="this.permissionChecker('download_return_plugin')"
      v-bind:is="donwloadPluginComponent"
      :pageLoader="pageLoad"
      ref="downloadPluginModal"
    ></component>
    <component
      v-bind:is="recordDetailComponent"
      :title="table_properties.title"
      :pageLoader="pageLoad"
      ref="recordDetail"
      :detailsUrl="detailsUrl"
    ></component>
    <component
      v-bind:is="manageHeaders"
      ref="manageHeadersComponent"
    ></component>
    <div v-if="$slots.bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";
import DataTableActionSelector from "@/own/components/DataTableActionSelector.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import Pagination from "@/own/components/pagination/Pagination.vue";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar.vue";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar.vue";

import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";
import SwalService from "@/core/services/swal.service";
import ManageHeaders from "@/own/components/datahub/Webhooks/ManageHeaders.vue";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  props: {
    id_field_name: {
      type: String,
      default: "id",
    },
    /** @type {{UPDATE_TABLE_STRUCTURE:string,UPDATE_TABLE_DATA:string,SET_TABLE_SORTBY:string,SET_TABLE_FILTER:string,SET_TABLE_PERPAGE:string,SET_TABLE_PAGE:string,EXPORT_TABLE_DATA:string}} */
    table_state: {
      type: Object,
      required: true,
    },
    /** @type {{getTableFilters:string,getTableState:string,getTableData:string,getTableHeaders:string,getTableProperties:string,getTableExportUrl:string,getTablePermissions:string}} */
    store_names: {
      type: Object,
      required: true,
    },
    addComponent: {},
    editComponent: {},
    duplicateComponent: {},
    uploadComponent: {},
    multiUploadComponent: {},
    generateComponent: {},
    printComponent: {},
    detailsComponent: {},
    donwloadPluginComponent: {},
    recordDetailComponent: null,
    addButtonText: {},
    isAddBtnCustom: { required: false, type: Boolean, default: false },
    delete_url: {
      type: String,
    },
    /** @type {{activate: string, delete: string, status: string}} */
    urls: {
      type: Object,
    },
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
    bulkRemoveOption: {
      type: Boolean,
      default: false,
      required: false,
    },
    isFilterRequiredForExport: {
      type: Boolean,
      default: false,
    },
    actionsComponent: {
      default: () => DataTableActionSelector,
    },
    isWarehouseRequired: {
      type: Boolean,
      default: false,
    },
    skipAddButton: { type: Boolean, default: false },
    skipEdit: { type: Boolean, default: false },
    overrideActionsWith: { type: Array, default: () => [] },
  },
  components: {
    DataTableActionSelector,
    DatatableDetail,
    FilterSidebar,
    ExportSidebar,
    Pagination,
    ComponentSelector,
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
    multiExportType: null,
    multiExportTypeColumns: [],
    manageHeaders: ManageHeaders,
  }),
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    export_types() {
      let types = [];
      if (this.store_names.getExportTypes) {
        types = this.$store.getters[this.store_names.getExportTypes];
      } else {
        types = [];
      }
      return types;
    },
    multiExportColumns() {
      return this.multiExportTypeColumns;
    },
    detailsUrl() {
      let data;
      try {
        data = this.urls.details;
      } catch {
        data = null;
      }
      return data;
    },
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    table_permissions: function () {
      /** @type {Array<string>} */
      let permissions = [];
      try {
        if (this.$store.getters[this.store_names.getTablePermissions]) {
          permissions = this.$store.getters[
            this.store_names.getTablePermissions
          ].map(function (perm) {
            return perm.name;
          });
        }
      } catch (error) {
        // this.$swal("Can not load table permissions!");
      }
      return permissions;
    },
    table_properties: function () {
      /** @type {{title:string, description: string}} */
      let properties = null;
      try {
        properties = this.$store.getters[this.store_names.getTableProperties];
      } catch (error) {
        // this.$swal("Can not load table properties!");
      }
      return properties;
    },
    orderBy: {
      get: function () {
        /** @type {string} */
        let sortBy = this.$store.getters[this.store_names.getTableState].sortBy;
        return sortBy;
      },
      set: function (newValue) {
        let state = {
          ...this.$store.getters[this.store_names.getTableState],
          ...(this.isWarehouseRequired && {
            warehouse_id: this.selectedWarehouse,
          }),
        };
        if (newValue) {
          this.$store.commit(this.table_state.SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, state);
        } else {
          this.$store.commit(this.table_state.SET_TABLE_SORTBY, "");
          this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, state);
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (
          this.$store.getters[this.store_names.getTableState].sortOrder ===
          "asc"
        ) {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        let state = {
          ...this.$store.getters[this.store_names.getTableState],
          ...(this.isWarehouseRequired && {
            warehouse_id: this.selectedWarehouse,
          }),
        };
        if (newValue === true) {
          this.$store.commit(this.table_state.SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(this.table_state.SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, state);
      },
    },
    headers: function () {
      /**
       * @type {Array<
       {
       value: string,
       text: string,
       type: string,
       sortable: string,
       exportable: string,
       visible: string,
       mobile_visible: string,
       align: string,
       itemClass: string,
       width: string,
       class: string,
       export_order: integer,
       }>
       }
       */
      let headers = [];
      try {
        if (this.$vuetify.breakpoint.smAndDown) {
          headers = [
            ...JSON.parse(
              JSON.stringify(
                this.$store.getters[this.store_names.getTableHeaders].filter(
                  (col) => col.visible === true && col.mobile_visible === true
                )
              )
            ),
          ];
        } else {
          headers = [
            ...JSON.parse(
              JSON.stringify(
                this.$store.getters[this.store_names.getTableHeaders].filter(
                  (col) => col.visible === true
                )
              )
            ),
          ];
        }
        headers.forEach((header) => {
          let langClass = " text-left ";
          let actionLangClass = " pr-10 ";
          if (this.$vuetify.rtl) {
            langClass = " text-right ";
            actionLangClass = " pl-10 ";
            header.align = "right";
          }
          header.class =
            "text-muted fw-bolder font-size-sm text-uppercase poppins-ls zindex-5 ma-0" +
            langClass;
          header.itemClass = "second-text";
          header.text = this.$t(header.text);
          header.width = undefined;
          if (header.value === "action") {
            header.class =
              "text-muted fw-bolder font-size-sm text-uppercase poppins-ls zindex-5 ma-0" +
              actionLangClass;
          }
        });
      } catch {
        // headers = this.$store.getters[this.store_names.getTableHeaders].filter(
        //   (col) => col.visible === true
        // );
      }

      return headers;
    },
    exportHeaders: function () {
      /** @type {Array<{order:string,text:string,value:string}>} */
      let exportHeaders = [];
      try {
        exportHeaders = this.$store.getters[
          this.store_names.getTablePermissions
        ].filter((col) => col.name === "export")[0].columns;
      } catch (error) {
        exportHeaders = [];
      }
      return exportHeaders;
    },
    items: function () {
      /**
       * @type {Array<{}>}
       * @example [{"id":891,"email":"rafi.ogchy@gmail.com","extra_data":null}]
       */
      let items = [];
      try {
        items = this.$store.getters[this.store_names.getTableData].data;
      } catch {
        items = [];
      }
      return items;
    },
    //-------------------activities------------------------
    actions: function () {
      if (this.overrideActionsWith.length) {
        return [...this.overrideActionsWith];
      }
      return [
        {
          name: "view",
          title: this.$t("view"),
          type: "normal",
          method: "activateView",
          isVisible: this.permissionChecker("show"),
        },
        {
          name: "reset",
          title: this.$t("reset"),
          type: "normal",
          method: "resetItem",
          isVisible: this.permissionChecker("reset"),
        },
        {
          name: "reset_password",
          title: this.$t("reset_password"),
          type: "normal",
          method: "activateAction",
          isVisible: this.permissionChecker("activate"),
        },
        {
          name: "refresh_token",
          title: this.$t("refresh_token"),
          type: "normal",
          method: "generateAction",
          isVisible: this.permissionChecker("generate"),
        },
        {
          name: "status",
          title: this.$t("status"),
          type: "status",
          method: "statusAction",
          isVisible: this.permissionChecker("status"),
        },
        {
          name: "update",
          title: this.$t("edit"),
          type: "normal",
          method: "editItem",
          isVisible: this.permissionChecker("update") && !this.skipEdit,
        },
        {
          name: "delete",
          title: this.$t("remove"),
          type: "normal",
          method: "removeItem",
          isVisible: this.permissionChecker("destroy"),
        },
        {
          name: "export",
          title: this.$t("export"),
          type: "normal",
          method: "exportChecklist",
          isVisible: this.permissionChecker("export_checklists"),
        },
        {
          name: "duplicate",
          title: this.$t("duplicate"),
          type: "normal",
          method: "duplicateAction",
          isVisible: this.permissionChecker("duplicate"),
        },
        {
          title: this.$t("install_plugin"),
          type: "normal",
          method: "downloadPlugin",
          isVisible: this.permissionChecker("download_return_plugin"),
        },
        {
          name: "approve",
          title: this.$t("approve"),
          type: "normal",
          method: "approveAction",
          isVisible: this.permissionChecker("approve"),
        },
        {
          name: "complete",
          title: this.$t("complete"),
          type: "normal",
          method: "completeAction",
          isVisible: this.permissionChecker("complete"),
        },
        {
          name: "cancel",
          title: this.$t("cancel"),
          type: "normal",
          method: "cancelAction",
          isVisible: this.permissionChecker("approve"),
        },
        {
          name: "adjustment",
          title: this.$t("add_adjustment"),
          type: "normal",
          method: "adjustmentAction",
          isVisible: this.permissionChecker("create_adjustment"),
        },
        // {
        //   title: "Active",
        //   type: "status",
        //   method: "statusAction",
        //   isVisible: this.permissionChecker("status"),
        // },
      ];
    },
    //-------------------pagination----------------------
    getitemPerPage: function () {
      /** @type {number} */
      let itemPerPage =
        this.$store.getters[this.store_names.getTableState].per_page;
      return itemPerPage;
    },
    getpageNumber: function () {
      /** @type {number} */
      let pageNumber = this.$store.getters[this.store_names.getTableState].page;
      return pageNumber;
    },
    totalItems: function () {
      /** @type {number} */
      let totalItems = this.$store.getters[this.store_names.getTableData].total;
      return totalItems;
    },
    pageCount: function () {
      /** @type {number} */
      let pageCount =
        this.$store.getters[this.store_names.getTableData].last_page;
      return pageCount;
    },
    itemPerPage: function () {
      /** @type {number} */
      let itemPerPage =
        this.$store.getters[this.store_names.getTableState].per_page;
      return itemPerPage;
    },
    //-------------------filter----------------------
    filters: function () {
      /** @type {Array<{name: string, title: string, type: string, related_columns: string[]}>} */
      let filters = [];
      try {
        filters = this.$store.getters[this.store_names.getTableFilters];
      } catch {
        filters = [];
      }
      return filters;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    hasTopRightActions: function () {
      return !!this.$scopedSlots.topRightActions;
    },
  },
  methods: {
    clearSelected() {
      this.selected = [];
    },
    actionMultiExport(export_type) {
      this.multiExportType = export_type.type;
      this.multiExportTypeColumns = export_type.columns;
      this.$nextTick(() => {
        this.handleExportSidebarClick();
      });
    },
    showRecordDetail(val, { item }) {
      if (!this.recordDetailComponent) {
        return;
      }
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$nextTick(() => {
        this.$refs.recordDetail.toggleModal();
      });
    },
    handleExportSidebarClick() {
      this.$refs.exportSidebar.$data.exportSidebar = true;
    },
    handleFilterSidebarClick() {
      this.$refs.filterSidebar.$data.filterSidebar = true;
    },
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    //\\
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    permissionChecker(permType) {
      return this.table_permissions.includes(permType);
    },
    handle_function_call(function_name, prop) {
      if (this.overrideActionsWith.length) {
        this.$emit(function_name, prop);
      } else {
        this[function_name](prop);
      }
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      let state = {
        ...this.$store.getters[this.store_names.getTableState],
        ...((this.isWarehouseRequired || this.isWarehouseRequired == 0) && {
          warehouse_id: this.selectedWarehouse,
        }),
      };
      this.pageLoad(true);
      await this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, state)
        .then(() => {
          this.selected = [];
        });
      this.$forceUpdate();
      this.pageLoad(false);
    },

    showAddItemModal() {
      this.$store.commit(SET_ITEM_FOR_ACTION, null);
      this.$refs.addItem.toggleModal();
    },
    showUploadModal(type) {
      this.$router.push({
        name: "settings.uploads",
        query: { type: type },
      });
      // this.$refs.upload.toggleUploadModal();
    },
    showGenerateModal() {
      this.$refs.generate.toggleModal();
    },
    applyReprocessAction() {
      if (this.selected.length > 0) {
        const data = { id: this.selected.map((row) => row.id) };
        ApiService.post(this.urls.reprocess, data)
          .then(() => {
            SwalService.successMessage({
              title: this.$t("reprocess"),
              html: this.$t("data_has_been_reprocessed"),
            });
            this.selected = [];
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      } else {
        SwalService.warningMessage({
          html: this.$t("please_select_at_least_one_record"),
        });
      }
    },
    //----------------------activities-------------------------
    activateView(item) {
      let routeData = this.$router.resolve({
        name: "invoiceTemp",
        query: { id: item.id },
      });
      window.open(routeData.href, "_blank");
    },
    resetItem(item) {
      const data = { id: item.id };
      ApiService.post(this.urls.reset, data)
        .then(() => {
          SwalService.successMessage({
            title: this.$t("reset"),
            html: this.$t("record_has_been_reset"),
          });

          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    editItem(item) {
      // this.$refs.editModal.$props.itemForAction = item;
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.editModal.toggleModal();
    },
    exportChecklist(item) {
      this.$refs.editModal.exportChecklist(item);
    },
    removeItem(item) {
      SwalService.warningConditionMessage(
        {
          title: SwalService.titles.are_you_sure,
          html: SwalService.messages.you_wont_be_able_to_revert_this,
          confirmButtonText: SwalService.messages.yes_delete_it,
        },
        () => {
          this.pageLoad(true);
          //TODO: change data sending to id
          const data = { id: item.id };
          ApiService.post(this.delete_url, data)
            .then(() => {
              SwalService.successMessage({
                title: SwalService.titles.deleted,
                html: SwalService.messages.deleted(),
              });

              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      );
    },
    generateAction(item) {
      this.pageLoad(true);
      let data = { id: item.id };
      ApiService.post(this.urls.generate, data)
        .then((res) => {
          this.$emit("handleTokenModal", res.data.token);
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    activateAction(item) {
      this.pageLoad(true);
      let data = { id: item.id };
      ApiService.post(this.urls.activate, data)
        .then(() => {
          SwalService.successMessage({
            title: this.$t("activation"),
            html: this.$t("activation_succeed_check_email"),
          });
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    approveAction(item) {
      this.pageLoad(true);
      // detailsModal
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.detailsModal.$props.type = "approve";
      // this.$refs.detailsModal.$props.item = item;
      this.$nextTick(() => {
        this.$refs.detailsModal.toggleModal();
      });
    },
    adjustmentAction(item) {
      this.pageLoad(true);
      // detailsModal
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      // this.$refs.detailsModal.$props.item = item;
      this.$nextTick(() => {
        this.$refs.detailsModal.toggleModal();
      });
    },
    cancelAction(item) {
      this.pageLoad(true);
      let data = { id: item.id };
      ApiService.post(this.urls.cancel, data)
        .then(() => {
          SwalService.successMessage({
            html: this.$t("canceled"),
          });
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    completeAction(item) {
      this.pageLoad(true);
      // detailsModal
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.detailsModal.$props.type = "complete";
      // this.$refs.detailsModal.$props.item = item;
      this.$nextTick(() => {
        this.$refs.detailsModal.toggleModal();
      });
    },
    duplicateAction(item) {
      // this.pageLoad(true);
      // let data = { id: item.id };
      // ApiService.post(this.urls.dublicate, data)
      //   .then(() => {
      //     Swal.fire("Success", "Duplication succeed!", "success");
      //     this.updateTableContent();
      //   })
      //   .catch(() => {
      //     this.pageLoad(false);
      //   });

      // this.$refs.editModal.$props.itemForAction = item;
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.duplicateModal.toggleModal();
    },
    downloadPlugin(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.downloadPluginModal.toggleModal();
    },
    generateToken(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(this.urls.generateToken, {
        customer_system_code: item.systemCode,
      }).then(({ data }) => {
        SwalService.successMessage({
          title: this.$t("token_generated"),
          html: `${data.token}`,
        });
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    generateRefreshToken(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(this.urls.generateRefreshToken, {
        customer_system_code: item.systemCode,
      }).then(({ data }) => {
        SwalService.successMessage({
          title: this.$t("refresh_token_generated"),
          html: `${data.token}`,
        });
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    editActionFunction(props) {
      this.editItem(props[0]);
    },
    removeActionFunction(props) {
      this.removeItem(props[0]);
    },
    headersActionFunction(props) {
      this.$store.commit(SET_ITEM_FOR_ACTION, props[0]);
      this.$refs.manageHeadersComponent.toggleModal();
    },
    statusAction(item) {
      // TODO: translation effected condition
      if (item.status_label === "Cancelled") return;
      this.pageLoad(true);
      let data = { id: item.id };
      ApiService.post(this.urls.status, data)
        .then(() => {
          SwalService.successMessage({
            title: this.$t("activation"),
            html: this.$t("status_updated"),
          });
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    //------------------pagination-------------------
    setitemPerPage(val) {
      this.$store.commit(this.table_state.SET_TABLE_PERPAGE, val);
    },
    setpageNumber(val) {
      this.$store.commit(this.table_state.SET_TABLE_PAGE, val);
    },
    //---------------------export-------------------------
    isAnyFilterSelected() {
      return this.$store.getters[this.store_names.getTableState].filters
        .length > 0
        ? true
        : false;
    },
    downloadItem(columns) {
      if (this.isFilterRequiredForExport && !this.isAnyFilterSelected()) {
        this.$refs.exportSidebar.$data.exportSidebar = false;
        SwalService.warningMessage({
          html: this.$t("please_select_at_least_one_filter"),
        });
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = {
        columns: columns,
        filters: this.$store.getters[this.store_names.getTableState].filters,
        ...(this.isWarehouseRequired && {
          warehouse_id: this.selectedWarehouse,
        }),
      };

      if (this.export_types.length > 0) {
        data.export_type = this.multiExportType;
      }

      if (this.selected.length) {
        data.export_ids = this.selected.map((item) => item.id);
      }

      this.$store
        .dispatch(this.table_state.EXPORT_TABLE_DATA, data)
        .then(() => {
          if (this.$store.getters[this.store_names.getTableExportUrl]) {
            const link = document.createElement("a");
            link.href = this.$store.getters[this.store_names.getTableExportUrl];
            link.download = "Export.csv";
            link.click();
            link.remove();
          }
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          this.$refs.exportSidebar.$refs.export.toggleModal();
          // this.$refs.exportSidebar.$data.exportSidebar = false;
        });
    },

    //--------------------filter---------------------
    setTableFiltersValues(val) {
      this.$store.commit(this.table_state.SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters[this.store_names.getTableState],
        ...(this.isWarehouseRequired && {
          warehouse_id: this.selectedWarehouse,
        }),
      };
      state.page = 1;
      this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(this.table_state.SET_TABLE_PAGE, 1);

          // this.$store.commit(SET_PAGE_LOADING, false);
          this.$nextTick(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(this.table_state.SET_TABLE_FILTER, []);
      let state = {
        ...this.$store.getters[this.store_names.getTableState],
        ...(this.isWarehouseRequired && {
          warehouse_id: this.selectedWarehouse,
        }),
      };
      this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetSelectedItems() {
      if (this.selected.length === 0) {
        return;
      }
      let idArr = [];
      this.selected.map((item) => {
        idArr.push(item.id);
      });
      SwalService.warningConditionMessage(
        {
          title: SwalService.titles.are_you_sure,
          html: this.$t("you_will_reset_all_the_selected_items"),
          confirmButtonText: this.$t("yes_reset_them"),
        },
        () => {
          this.pageLoad(true);
          //TODO: change data sending to id
          const data = { id: idArr };
          ApiService.post(this.urls.reset, data)
            .then(() => {
              SwalService.successMessage({
                title: this.$t("reset"),
                html: this.$t("records_has_been_reset"),
              });

              this.selected = [];
              this.updateTableContent();
            })
            .catch(() => {
              this.selected = [];
              this.pageLoad(false);
            });
        }
      );
    },
    rowClicked() {
      //emit selected to parent
      setTimeout(() => {
        this.$emit("item-selected", this.selected);
      }, 50);
    },
  },
  beforeDestroy() {
    this.$store.commit(this.table_state.SET_TABLE_FILTER, []);
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
