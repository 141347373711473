var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('div',{staticClass:"d-flex align-center align-content-left w-auto py-1"},[_c('ShowImage',{attrs:{"item":{ url: _vm.value.image, first_letter: _vm.value.text1[0] }}}),_c('div',{staticClass:"name-main"},[_c('span',{staticClass:"first-text",class:{
        'mr-3 text-right': _vm.$vuetify.rtl,
        'ml-3 text-left': !_vm.$vuetify.rtl,
      },staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.value.text1)+" ")]),_c('span',{staticClass:"first-text ml-3 text-left",class:{
        'mr-3 text-right': _vm.$vuetify.rtl,
        'ml-3 text-left': !_vm.$vuetify.rtl,
      },staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.value.text2)+" ")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }