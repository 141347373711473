export default {
  methods: {
    getvh(percent) {
      let h = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );
      return (percent * h) / 100;
    },
  },
};
