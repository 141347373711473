<template>
  <div>
    <a
      href="#"
      class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
      @click="toggleModal"
      >View</a
    >
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
      v-if="isIntegrationReady"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Validation</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->

          <div v-if="pageFlag === 'countries'">
            <Countries
              :integration_id="integration_id"
              :goBack="setOriginPage"
              :loader="pageLoad"
              :refresher="refresher"
            ></Countries>
          </div>
          <div v-if="pageFlag === 'suppliers'">
            <Suppliers
              :integration_id="integration_id"
              :goBack="setOriginPage"
              :loader="pageLoad"
              :refresher="refresher"
            ></Suppliers>
          </div>
          <div v-if="pageFlag === 'shippingMethods'">
            <shipping-method
              :integration_id="integration_id"
              :goBack="setOriginPage"
              :loader="pageLoad"
              :refresher="refresher"
            ></shipping-method>
          </div>
          <div v-if="pageFlag === 'shippingCustomFields'">
            <shipping-custom
              :integration_id="integration_id"
              :goBack="setOriginPage"
              :loader="pageLoad"
              :refresher="refresher"
            ></shipping-custom>
          </div>
          <div v-if="pageFlag === 'skus'">
            <skus-mapping
              :integration_id="integration_id"
              :goBack="setOriginPage"
              :loader="pageLoad"
              :refresher="refresher"
            ></skus-mapping>
          </div>
          <div v-if="pageFlag === 'statusprocessing'">
            <status-processing
              :integration_id="integration_id"
              :goBack="setOriginPage"
              :loader="pageLoad"
              :refresher="refresher"
            ></status-processing>
          </div>
          <div v-if="pageFlag === 'origin'">
            <v-data-table
              v-model="selected"
              fixed-header
              light
              dense
              show-select
              :items="integration.validations.data"
              :headers="headers"
              :items-per-page="itemPerPage"
              item-key="validation_id"
              class="m-2 font-weight-bold"
              hide-default-footer
            >
              <!--    progress-->
              <template v-slot:progress>
                <div style="display: flex" class="justify-content-center">
                  <v-progress-circular
                    :size="40"
                    :width="6"
                    style="color: deeppink; display: flex"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
              <!--    progress end-->
              <!--    no-data-->
              <template v-slot:no-data> NO DATA HERE! </template>
              <template v-slot:no-results> NO RESULTS HERE! </template>
              <!--    no-data end-->
              <!-- column -->
              <template #item.status="{ value }">
                <validation-status :value="value"></validation-status>
              </template>
              <template #item.action="{ item, value }">
                <v-menu
                  offset-y
                  bottom
                  content-class="justify-content-left rounded"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" x-small v-on="on" icon color="info">
                      <v-icon>mdi-settings</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(action, index) in actions(
                        item.separate_page_requires
                      )"
                      :key="index"
                      @click="handle_function_call(action.method, item)"
                    >
                      <v-card-text>
                        <a class="text-left h6 btn ma-0 pa-0">
                          {{
                            action.title == "Enable"
                              ? item.status == true
                                ? "Disable"
                                : "Enable"
                              : action.title
                          }}
                        </a>
                      </v-card-text>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <!-- column end -->
            </v-data-table>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
      <order-prefix
        :integration_id="integration_id"
        :loader="pageLoad"
        ref="orderPrefix"
      ></order-prefix>
      <confirm-order
        :integration_id="integration_id"
        :loader="pageLoad"
        ref="confirmedOrders"
      ></confirm-order>
    </v-dialog>
  </div>
</template>

<script>
import {
  UPDATE_TABLE_STRUCTURE,
  UPDATE_TABLE_DATA,
} from "@/core/services/store/integrationsValidation.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import OrderPrefix from "@/own/components/datahub/integrations/validation/OrderPrefix.vue";
import ConfirmOrder from "@/own/components/datahub/integrations/validation/ConfirmOrder.vue";
import Countries from "@/own/components/datahub/integrations/validation/countries/Countries.vue";
import Suppliers from "@/own/components/datahub/integrations/validation/supplierMapping/Suppliers.vue";
import ShippingMethod from "@/own/components/datahub/integrations/validation/shippingMethod/ShippingMethod.vue";
import StatusProcessing from "@/own/components/datahub/integrations/validation/statusProcessing/StatusProcessing.vue";
import ShippingCustom from "@/own/components/datahub/integrations/validation/shippingCustom/ShippingCustom.vue";
import SkusMapping from "@/own/components/datahub/integrations/validation/skusMapping/SkusMapping.vue";
import ValidationStatus from "@/own/components/datahub/integrations/validation/ValidationStatus.vue";
import Apiservice from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "ValidationDialog",
  props: ["integration", "title"],
  components: {
    ValidationStatus,
    Countries,
    OrderPrefix,
    Suppliers,
    // Returned,
    ShippingCustom,
    ConfirmOrder,
    ShippingMethod,
    SkusMapping,
    StatusProcessing,
  },
  data: () => ({
    selected: [],
    dialog: false,
    offset: true,
    columnsModal: false,

    pageFlag: "origin",
    itemPerPage: 20,
    headers: [
      {
        text: "ID",
        value: "validation_id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Action",
        value: "action",
      },
    ],
  }),

  computed: {
    isIntegrationReady: function () {
      return !!this.integration;
    },
    integration_id: function () {
      if (this.integration) {
        return this.integration.id;
      } else {
        return null;
      }
    },
  },
  methods: {
    actions(separate_page_requires) {
      if (separate_page_requires) {
        return [
          {
            title: "Enable",
            method: "enableItem",
          },
          {
            title: "Edit",
            method: "editItem",
          },
          {
            title: "Remove",
            method: "removeItem",
          },
        ];
      } else {
        return [
          {
            title: "Enable",
            method: "enableItem",
          },
          {
            title: "Remove",
            method: "removeItem",
          },
        ];
      }
    },
    setDataToComponent() {
      if (this.isIntegrationReady) {
        this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
        this.$store.dispatch(UPDATE_TABLE_DATA, {
          integration_id: this.integration.id,
        });
      }
    },
    toggleModal() {
      this.setOriginPage();
      this.dialog = !this.dialog;
    },
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    enableItem(item) {
      this.pageLoad(true);
      const data = {
        validation_id: item.validation_id,
        integration_id: this.integration_id,
        status: !item.status,
      };
      Apiservice.post("/datahub/integrations/validationStatus", data)
        .then(() => {
          this.refresher();
          Swal.fire("Updated!", "This status has been updated.", "success");
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    editItem(item) {
      if (item.separate_page_requires === "orderPrefix") {
        this.$refs.orderPrefix.toggleModal();
      } else if (item.separate_page_requires === "confirmedOrders") {
        this.$refs.confirmedOrders.toggleModal();
      } else {
        this.pageFlag = item.separate_page_requires;
      }
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          const data = {
            validation_id: item.validation_id,
            integration_id: this.integration_id,
          };
          Apiservice.post("/datahub/integrations/validationDestroy", data)
            .then(() => {
              this.refresher();
              Swal.fire(
                "Deleted!",
                "This validation has been updated.",
                "success"
              );
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    setOriginPage() {
      this.pageFlag = "origin";
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    refresher() {
      this.$store
        .dispatch(
          "getINTEGRATIONSTableData",
          this.$store.getters["getINTEGRATIONSTableState"]
        )
        .then(() => {});
      this.$forceUpdate();
    },
    addValidation() {
      this.$refs.create_Validation.toggleModal();
    },
  },
};
</script>
