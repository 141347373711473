<template>
  <div class="d-flex">
    <div
      class="rounded mr-2"
      v-for="(item, index) in color"
      :key="index"
      :style="{ backgroundColor: item }"
      style="width: 20px; height: 20px; border: 1px solid black"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["color"],
};
</script>
