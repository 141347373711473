<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <IntegrationDataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :actionsComponent="componentForActions"
      :delete_url="delete_url"
      :urls="urls"
    />
  </div>
</template>

<script>
import IntegrationDataTable from "@/own/components/datahub/integrations2/IntegrationDataTable.vue";
import AddItem from "@/own/components/orderManagement/CountriesConfigurations/AddItem.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PER_PAGE,
  SET_TABLE_SORT_BY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/integrationCountries.module";
import DataTableActionSelectorNew from "@/own/components/DataTableActionSelectorNew.vue";

export default {
  name: "IntegrationCountries",
  props: ["integration"],
  components: { IntegrationDataTable },
  data: () => ({
    componentForAdding: AddItem,
    componentForActions: DataTableActionSelectorNew,
    delete_url: `/validations/datahub/integration_countries/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORT_BY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PER_PAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getINTEGRATIONCOUNTRIESTableFilters",
      getTableState: "getINTEGRATIONCOUNTRIESTableState",
      getTableData: "getINTEGRATIONCOUNTRIESTableData",
      getTableHeaders: "getINTEGRATIONCOUNTRIESTableHeaders",
      getTableProperties: "getINTEGRATIONCOUNTRIESTableProperties",
      getTableExportUrl: "getINTEGRATIONCOUNTRIESExportUrl",
      getTablePermissions: "getINTEGRATIONCOUNTRIESTablePermissions",
    },
    urls: {
      // status: `/order_management/subscriptions/status`,
      // delete: `/datahub/integration_order_status/destroy`,
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {
        integration_id: this.integration.id,
      });
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        integration_id: this.integration.id,
      });
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
