import Swal from "sweetalert2";
import vueI18n from "@/core/plugins/vue-i18n";

const SwalService = {
  /**
   * Message types available for SweetAlert2.
   * @type {Array<string>}
   */
  messageTypes: ["success", "warning", "info", "error"],

  /**
   * Predefined titles for different message types, translated using vue-i18n.
   * @type {Object<string, string>}
   */
  titles: {
    updated: vueI18n.t("updated"),
    created: vueI18n.t("created"),
    error: vueI18n.t("error"),
    warning: vueI18n.t("warning"),
    success: vueI18n.t("success"),
    modified: vueI18n.t("modified"),
    are_you_sure: vueI18n.t("are_you_sure"),
    deleted: vueI18n.t("deleted"),
    imported: vueI18n.t("imported"),
    enabled: vueI18n.t("enabled"),
  },

  /**
   * Predefined messages with translations and optional dynamic data.
   * @type {Object<string, Function|string>}
   */
  messages: {
    added: (data = vueI18n.t("record")) =>
      vueI18n.t("the_data_has_been_added", { data }),
    created: (data = vueI18n.t("record")) =>
      vueI18n.t("the_data_has_been_created", { data }),
    updated: (data = vueI18n.t("record")) =>
      vueI18n.t("the_data_has_been_updated", { data }),
    deleted: (data = vueI18n.t("record")) =>
      vueI18n.t("the_data_has_been_deleted", { data }),
    filter_is_required: (data) => vueI18n.t("filter_is_required", { data }),
    data_is_invalid: vueI18n.t("data_is_invalid"),
    please_select_to_export: vueI18n.t(
      "please_select_at_least_one_column_to_export"
    ),
    successifully_completed: vueI18n.t("successifully_completed"),
    please_select_at_least_one_filter: vueI18n.t(
      "please_select_at_least_one_filter"
    ),
    you_wont_be_able_to_revert_this: vueI18n.t(
      "you_wont_be_able_to_revert_this"
    ),
    yes_delete_it: vueI18n.t("yes_delete_it"),
    date_is_required: vueI18n.t("date_is_required"),
    no: vueI18n.t("no"),
    something_went_wrong: vueI18n.t("something_went_wrong"),
  },

  /**
   * Display a success message using SweetAlert2.
   * @param {Object} [data={}] - Optional data to override default parameters.
   * @param {string} [data.title=this.titles.success] - The title of the message.
   * @param {string} [data.html=""] - The HTML content of the message.
   * @param {string} [data.icon=this.messageTypes[0]] - The icon type (e.g., success, error).
   * @param {number} [data.timer=2500] - The time in milliseconds before the message closes.
   * @param {boolean} [data.showConfirmButton=false] - Whether to show the confirm button.
   */
  successMessage(data = {}) {
    Swal.fire({
      title: this.titles.success,
      html: "",
      icon: this.messageTypes[0],
      timer: 2500,
      showConfirmButton: false,
      ...data,
    });
  },

  /**
   * Display an error message using SweetAlert2.
   * @param {Object} [data={}] - Optional data to override default parameters.
   * @param {string} [data.title=this.titles.error] - The title of the message.
   * @param {string} [data.html=""] - The HTML content of the message.
   * @param {string} [data.icon=this.messageTypes[3]] - The icon type (e.g., success, error).
   * @param {number} [data.timer=2500] - The time in milliseconds before the message closes.
   * @param {boolean} [data.showConfirmButton=false] - Whether to show the confirm button.
   */
  errorMessage(data = {}) {
    Swal.fire({
      title: this.titles.error,
      html: "",
      icon: this.messageTypes[3],
      timer: 2500,
      showConfirmButton: false,
      ...data,
    });
  },

  /**
   * Display a warning message using SweetAlert2.
   * @param {Object} [data={}] - Optional data to override default parameters.
   * @param {string} [data.title=this.titles.warning] - The title of the message.
   * @param {string} [data.html=""] - The HTML content of the message.
   * @param {string} [data.icon=this.messageTypes[1]] - The icon type (e.g., success, error).
   * @param {number} [data.timer=2500] - The time in milliseconds before the message closes.
   * @param {boolean} [data.showConfirmButton=false] - Whether to show the confirm button.
   */
  warningMessage(data = {}) {
    Swal.fire({
      title: this.titles.warning,
      html: "",
      icon: this.messageTypes[1],
      timer: 2500,
      showConfirmButton: false,
      ...data,
    });
  },

  /**
   * Display an informational message using SweetAlert2.
   * @param {Object} [data={}] - Optional data to override default parameters.
   * @param {string} [data.title=this.titles.warning] - The title of the message.
   * @param {string} [data.html=""] - The HTML content of the message.
   * @param {string} [data.icon=this.messageTypes[2]] - The icon type (e.g., success, error).
   * @param {number} [data.timer=2500] - The time in milliseconds before the message closes.
   * @param {boolean} [data.showConfirmButton=false] - Whether to show the confirm button.
   */
  infoMessage(data = {}) {
    Swal.fire({
      title: this.titles.warning,
      html: "",
      icon: this.messageTypes[2],
      timer: 2500,
      showConfirmButton: false,
      ...data,
    });
  },

  /**
   * Display a warning message with a confirmation dialog using SweetAlert2.
   * @param {Object} [data={}] - Optional data to override default parameters.
   * @param {string} [data.title=this.titles.are_you_sure] - The title of the message.
   * @param {string} [data.html=""] - The HTML content of the message.
   * @param {string} [data.icon="warning"] - The icon type (e.g., success, error).
   * @param {boolean} [data.showCancelButton=true] - Whether to show the cancel button.
   * @param {string} [data.confirmButtonColor="#3085d6"] - The color of the confirm button.
   * @param {string} [data.cancelButtonColor="#d33"] - The color of the cancel button.
   * @param {string} [data.confirmButtonText=vueI18n.t("yes")] - The text of the confirm button.
   * @param {string} [data.cancelButtonText=vueI18n.t("no")] - The text of the cancel button.
   * @param {Function} confirmed - Callback function to execute if the user confirms.
   * @param {Function} reject - Callback function to execute if the user cancels.
   * @returns {Promise<void>} - A promise that resolves when the alert is handled.
   */
  async warningConditionMessage(
    data = {},
    confirmed = () => {},
    reject = () => {}
  ) {
    await Swal.fire({
      title: this.titles.are_you_sure,
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: vueI18n.t("yes"),
      cancelButtonText: vueI18n.t("no"),
      ...data,
    }).then((result) => {
      if (result.isConfirmed) {
        confirmed(); // Call the confirmed callback if the user confirms
      } else {
        reject(); // Call the reject callback if the user cancels
      }
    });
  },
};

// Export the SwalService for use in other parts of the application
export default SwalService;
