<template>
  <span :class="btnColorSetter(value)" class="my-1">
    {{ value == true ? "Enabled" : "Disabled" }}
  </span>
</template>

<script>
/**
 * component for displaying styled user status
 * api name =
 */
export default {
  name: "ValidationStatus",
  props: ["value"],
  methods: {
    btnColorSetter(tp) {
      var res = " px-3 py-1 uppercase status-text-color rounded text-center";
      if (tp == true) {
        return res + " status-enabled ";
      } else {
        return res + " status-disabled ";
      }
    },
  },
};
</script>

<style scoped>
.status-text-color {
  border-radius: 8px !important;
  font-size: 10px;

  text-transform: uppercase;
  font-weight: 800 !important;
}
.status-enabled {
  color: #22912c;
  background-color: #a6d48f !important;
}
.status-disabled {
  color: #6a6a6a;
  background-color: #c4c4c4 !important;
}
</style>
