<template>
  <div class="pt-4 d-flex justify-space-between align-center flex-wrap">
    <v-col class="pr-0" cols="6" sm="3" lg="2" order="0">
      <PageInfo
        :page="pageNumber"
        :totalNumber="totalItems"
        :perPage="itemPerPage"
      ></PageInfo>
    </v-col>
    <v-col cols="12" sm="7" lg="8" order-sm="1" order="2">
      <v-pagination
        v-model="pageNumber"
        :length="pageCount"
        total-visible="7"
        :color="$store.getters.currentUser.theme['text_color']"
        class="poppins"
        flat
      ></v-pagination>
    </v-col>
    <v-col cols="6" sm="2" order-sm="2" order="1">
      <v-select
        v-model="itemPerPage"
        class="poppins"
        :items="pages"
        label=""
        :color="$store.getters.currentUser.theme['text_color']"
        dense
        flat
        outlined
        hide-details
      ></v-select>
    </v-col>
  </div>
</template>

<script>
import PageInfo from "@/own/components/pagination/PageInfo.vue";
export default {
  components: { PageInfo },
  props: [
    "setitemPerPage",
    "getitemPerPage",
    "getpageNumber",
    "setpageNumber",
    "totalItems",
    "pageCount",
    "updateTableContent",
  ],
  computed: {
    pages: function () {
      return [25, 50, 75, 100];
    },
    itemPerPage: {
      get: function () {
        return this.getitemPerPage;
      },
      set: function (newValue) {
        this.setitemPerPage(newValue);
        this.updateTableContent();
      },
    },
    pageNumber: {
      get: function () {
        return this.getpageNumber;
      },
      set: function (newValue) {
        this.setpageNumber(newValue);
        this.updateTableContent();
      },
    },
  },
};
</script>
