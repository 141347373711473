<template>
  <v-autocomplete
    class="mx-1"
    multiple
    :items="filter['values']"
    v-model="filterData"
    :label="$t(filter.title)"
    item-text="text"
    item-value="index"
    dense
    messages=""
    clearable
    small-chips
    deletable-chips
    outlined
  >
    <template v-slot:item="{ item }">
      <show-url-image :url="item.image" class="mr-3"> </show-url-image>
      <v-list-item-content>
        <v-list-item-title v-text="item.text"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
import ShowUrlImage from "@/own/components/datatable/ShowUrlImage.vue";
export default {
  name: "FilterMultiSelectWithImage",
  components: { ShowUrlImage },
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    filterData: [],
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}`]) {
      this.filterData = this.filterAllData[`${this.filter.name}`];
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
  },
  methods: {
    resetFilterData() {
      this.filterData = [];
    },
  },
  watch: {
    filterData: {
      handler() {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData;
        this.$store.commit(SET_FILTERS_DATA, all);
      },
      deep: true,
    },
  },
};
</script>
