<template>
  <div class="mx-4 mb-16 pb-6 position-relative">
    <v-card class="ma-0 pa-0 elevation-0">
      <v-card-title class="d-flex justify-space-between align-center">
        <span class="poppins text-dark-75 font-size-h3">{{
          $t("select_all")
        }}</span>
        <div
          class="form-check form-check-sm form-check-custom form-check-solid"
          :class="{
            ' ml-8': $vuetify.rtl,
            ' mr-8': !$vuetify.rtl,
          }"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="select_all"
            @change="selectAllColumns"
          />
        </div>
      </v-card-title>
      <v-card-text class="px-1 py-1">
        <perfect-scrollbar class="scroll overflow-y-hidden py-0">
          <draggable
            tag="div"
            :list="columns_to_export"
            class=""
            handle=".dragger"
            v-bind="dragOptions"
            @start="isDragging = true"
            @end="isDragging = false"
          >
            <transition-group type="transition" name="flip-list">
              <v-card
                outlined
                class="px-2 py-5 mx-1 mb-4 d-flex align-center justify-space-between align-content-center"
                v-for="(element, idx) in columns_to_export"
                :key="idx"
              >
                <div
                  class="d-flex align-content-center order-1"
                  :class="{
                    ' align-right': $vuetify.rtl,
                    ' align-center': !$vuetify.rtl,
                  }"
                >
                  <i class="dragger"><v-icon>mdi-menu</v-icon></i>
                  <div
                    class="pt-1 poppins font-size-h6 font-size-h5-sm"
                    :class="{
                      'pr-2': $vuetify.rtl,
                      'pl-2': !$vuetify.rtl,
                    }"
                  >
                    {{ element.text }}
                  </div>
                </div>

                <div
                  class="form-check form-check-sm form-check-custom form-check-solid order-2"
                  :class="{
                    ' ml-8': $vuetify.rtl,
                    ' mr-8 ml-1': !$vuetify.rtl,
                  }"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="element['exportable']"
                    @change="updateData"
                  />
                </div>
              </v-card>
            </transition-group>
          </draggable>
        </perfect-scrollbar>
      </v-card-text>
    </v-card>

    <div
      class="w-100 pa-6 bg-white d-flex justify-center align-center position-fixed zindex-5 bottom-0 right-0"
    >
      <div class="w-50 mr-4">
        <button
          @click="toggleModal"
          class="w-100 btn btn--export-filter d-flex align-center justify-center poppins font-size-lg line-height-xl ls1 text-uppercase"
        >
          <span class="svg-icon">
            <v-icon size="24"> mdi-chevron-left </v-icon>
          </span>
          {{ $t("clear") }}
        </button>
      </div>
      <div class="w-50 mr-1">
        <button
          class="w-100 btn iq-btn poppins text-no-wrap font-size-lg line-height-xl ls1 text-uppercase"
          @click.prevent="exportColumns"
        >
          {{ $t("export") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SwalService from "@/core/services/swal.service";
import draggable from "vuedraggable";

export default {
  name: "Export",
  props: {
    headers: {
      required: true,
      type: Array,
    },
    downloadItem: {
      required: true,
      type: Function,
    },
  },
  components: { draggable },
  data: () => ({
    select_all: true,
    export_modal: false,
    columns_to_export: [],
  }),
  mounted() {
    this.initiate();
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    initiate() {
      this.columns_to_export = [...this.headers];
      this.columns_to_export.forEach((col, index) => {
        this.columns_to_export[index] = { exportable: true, ...col };
      });
      this.selectAllColumns();
    },
    /**
     * to trigger computed data!
     */
    updateData() {
      this.columns_to_export = [...this.columns_to_export];
    },
    /**
     * convert data to old format to be compatible
     */
    convertToOldStyle() {
      let data = [];
      this.columns_to_export.forEach((col) => {
        if (col["exportable"]) {
          data.push(col.value);
        }
      });
      return data;
    },
    /**
     * handle submit button action
     */
    exportColumns() {
      if (this.columns_to_export.length > 0) {
        this.downloadItem(this.convertToOldStyle());
      } else {
        SwalService.warningMessage({
          html: this.$t("please_select_at_least_one_column_to_export"),
        });
      }
    },
    /**
     * change state of columns
     */
    selectAllColumns() {
      if (this.select_all) {
        this.columns_to_export.forEach((col, index) => {
          this.columns_to_export[index]["exportable"] = true;
        });
      } else {
        this.columns_to_export.forEach((col, index) => {
          this.columns_to_export[index]["exportable"] = false;
        });
      }
    },
    /**
     * toggle modal visibility
     */
    toggleModal() {
      if (!this.export_modal) {
        this.initiate();
      }
      this.export_modal = !this.export_modal;
    },
    /**
     * is all columns are exportable
     * @return {Boolean}
     */
    isAllColumnsExportable() {
      let is_all_exportable = true;
      this.columns_to_export.forEach((col) => {
        if (!col["exportable"]) {
          is_all_exportable = false;
        }
      });
      return is_all_exportable;
    },
    /**
     * select all state manager
     */
    handleSelectAllState() {
      if (this.isAllColumnsExportable()) {
        this.select_all = true;
      } else {
        this.select_all = false;
      }
    },
  },
  watch: {
    columns_to_export: function () {
      this.handleSelectAllState();
    },
    headers: function () {
      this.initiate();
    },
  },
};
</script>

<style scoped>
.dragger {
  cursor: move;
}
</style>
