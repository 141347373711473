<template>
  <span class="symbol symbol-35 symbol-light-success">
    <img alt="" v-if="url" :src="url" />
    <span
      v-else
      class="symbol-label font-size-h5 font-weight-bold"
      style="background-color: white"
    >
    </span>
  </span>
</template>

<script>
export default {
  name: "ShowOnluImage",
  props: ["url"],
};
</script>
